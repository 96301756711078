import React, { Component } from "react";
import { reAuth } from "actions/authActions";
// import Iframe from "@nicholasadamou/react-iframe";
import { connect } from "react-redux";
import SidePanelDemography from "../mapping/SidePanelDemography";
import { Box, Button, TextField } from '@mui/material';
import axios from "axios";

import "styles/components/map-submenu.css";


const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

class FypCms360 extends Component {
  state = {
    iframeRandomKey: 0,
    frameElement: null,
    frameDome: null,
    provinceFilter: 'national',
    // urlIframe: 'http://localhost:8000/cms360',
    urlIframe: 'https://realfyp.skyview360.io/cms360',
    isIframeLoaded : false,
    inputPlaceName : null,
    inputAreaName : null,
    region : '35'
  }

  // const handleFrameElement = React.useCallback(e => {
  //   setFrameElement(e.target);
  // }, []);

  

  handleFrameElement = val => {
    this.setState({
      frameElement: val,
      urlIframe: val.target.src,
      isIframeLoaded: true,
      // iframeRandomKey: this.state.iframeRandomKey + 1
    })

    // console.log(val.target);

    // console.log('yyyy');
    // let domSrc = val.target.contentWindow.location;
    // console.log('xxx : ' + domSrc);

    let domTarget = val.target.src;
    //   // .getElementById('clickbutton');
    console.log(domTarget);
  }

  handleInputPlace = val => {
    this.setState({
      inputPlaceName: val.target.value
    })
  }

  handleInputArea = val => {
    this.setState({
      inputAreaName: val.target.value
    })
  }

  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
  }

  render() {

    

    //document.cookie = "_ga_T0V3N321G9=GS1.1.1684627752.4.1.1684629006.0.0.0; _ga=GA1.1.2145395581.1684596817; _gid=GA1.2.784855966.1684596818; session=.eJwljktqBDEMBe_idRa29bPmMo1sScwQSKB7ZhVy93HItni8qp9y5BnXvdye5ys-yvHwcivoKVqnJc2qGDVbb1VsAROhGAAGsQoqQdaEDns2giWzRWNTYBjZ3bxxQCpFcyTvvVfrEVV8ckokxsq6VLmpJ5gwQB2OmmWHvK44_2uos2yyrjOP5_dnfP2xiNVYgoAdwcxivw-TUZO2KnM6ThAqv29ceUAG.ZGliBA.QUZNWo1IBGAwRWuFDalSoPMGbZ8; __cf_bm=9QLy0S6kToEE946CkfBCNT_jPQ0snF_v52T4tJJvUMg-1684627892-0-AbdPTJcYeuGQRt2JobDKOR6dPGhMMXjZgglB/9ziUmWOw0vgAvzEKXTPO85Sl+nBuqfgnbeRnfgltQIXu8lFink=; __stripe_sid=406afede-2211-47d0-8b1d-df20105c9c139b3221; __stripe_mid=75a96292-21dc-46ff-bd29-70476e4f9e29eb36b7; _gat_gtag_UA_165301827_1=1; SL_C_23361dd035530_SID=fZqe5vyIWMdYdeECtqN2_; SL_C_23361dd035530_VID=qQ6Ame0ZDQpWOcUASvwX8; SL_C_23361dd035530_KEY=c2f6c086696e911cee1fe4f798984693aa9c95c2";
    document.cookie = "session=.eJwtzjlOBEEMBdC7VEzgpTbPZVpl17dASCB1z0SIu9MB6YveTznyxPVeHs_zhbdyfOzyKI3qrDImR_Qmtbn7qECEZRcKHxCqbsYp2cwjkasNbpG8yNRho3cRidRocxqtdLEaZE05J9x0uQpNgyV0AdtiYlis2jdQ7sjrwvm_kT5uievM4_n9ia_bJpGqOAPOPaeGt3VXENIxMDpz6t5cy-8f931BLA.ZLkEXw.ZjCW7A9_t3Yl6nslhcsQFLgxoyg; _ga_T0V3N321G9=GS1.1.1689846865.1.1.1689847012.0.0.0; _ga=GA1.1.1206855762.1689846866; _ga_J5JX8PNYZ0=GS1.1.1689846865.1.1.1689847012.30.0.0; _gid=GA1.2.1065218545.1689846866; _clck=r0vm5x|2|fdg|0|1296; _clsk=sf3s5n|1689846989414|5|1|q.clarity.ms/collect; remember_token=5267|cc64b10534c00c03f0c124fb31f8a9ceedf4bde236fde25c22183ac887826fa2720a9ddbe5b52c7cbbcaecfdad21605d81fe509964d0a4289b2fc8539c8b5f71; _gat_gtag_UA_165301827_1=1"

    let domIframe = document.getElementById('iframe');
    // .contentDocument.getElementById('identity')

    if (domIframe != null) {
      console.log(domIframe);
    }

    // if (this.frameElement != null) {
    //   console.log('frame xxx');
    //   let domUsername = domIframe.contentWindow.document.getElementById('clickbutton');
    //   console.log(domUsername);
    // }

    // https://besttime.app/api/v1/radar/filter?q=pasar+in+jawa+timur&map_lat=-7.6221222&map_lng=112.3300435&lat_min=-8.101064&lat_max=-7.1431804&lng_min=111.8810029&lng_max=112.7790842&map_z=9&collection_id=col_66a6df8766624726b1ca27fb324c4751&api_key_private=pri_1cbe7061e77d44db93bbbbe7285d1e51&busy_conf=any&live_refresh=False

    return (
      <React.Fragment>
        <iframe style={{height: '100%', background: '#ddd !important', color: 'white !important', textColor: 'white !important'}}
          key={this.state.iframeRandomKey}
          id="iframe"
          sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-top-navigation-by-user-activation"
          src={this.state.urlIframe}

          // src="https://besttime.app/api/v1/radar/filter?q=restaurant+in+bandung&map_lat=-6.89&map_lng=107.61&lat_min=-6.926698&lat_max=-6.8583723&lng_min=107.5845435&lng_max=107.638605&map_z=13&api_key_private=pri_1cbe7061e77d44db93bbbbe7285d1e51&busy_conf=any&live_refresh=False&lat=-6.89&lng=107.61&radius=27304"
          headers={{
            "X-Frame-Options": "allow-from *",
            "Access-Control-Allow-Origin": "*",
            // Cookie: "_ga_T0V3N321G9=GS1.1.1684627752.4.1.1684629006.0.0.0; _ga=GA1.1.2145395581.1684596817; _gid=GA1.2.784855966.1684596818; session=.eJwljktqBDEMBe_idRa29bPmMo1sScwQSKB7ZhVy93HItni8qp9y5BnXvdye5ys-yvHwcivoKVqnJc2qGDVbb1VsAROhGAAGsQoqQdaEDns2giWzRWNTYBjZ3bxxQCpFcyTvvVfrEVV8ckokxsq6VLmpJ5gwQB2OmmWHvK44_2uos2yyrjOP5_dnfP2xiNVYgoAdwcxivw-TUZO2KnM6ThAqv29ceUAG.ZGliBA.QUZNWo1IBGAwRWuFDalSoPMGbZ8; __cf_bm=9QLy0S6kToEE946CkfBCNT_jPQ0snF_v52T4tJJvUMg-1684627892-0-AbdPTJcYeuGQRt2JobDKOR6dPGhMMXjZgglB/9ziUmWOw0vgAvzEKXTPO85Sl+nBuqfgnbeRnfgltQIXu8lFink=; __stripe_sid=406afede-2211-47d0-8b1d-df20105c9c139b3221; __stripe_mid=75a96292-21dc-46ff-bd29-70476e4f9e29eb36b7; _gat_gtag_UA_165301827_1=1; SL_C_23361dd035530_SID=fZqe5vyIWMdYdeECtqN2_; SL_C_23361dd035530_VID=qQ6Ame0ZDQpWOcUASvwX8; SL_C_23361dd035530_KEY=c2f6c086696e911cee1fe4f798984693aa9c95c2"
          }}

          onLoad={this.handleFrameElement}
          />
      </React.Fragment>
    );
  }
}

// const mapStateToProps = state => ({
//   map: state.map,
//   turnout: state.map.turnout
// });

// const mapDispatchToProps = {
  
// };
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(OverviewCrowd);

export default FypCms360;
